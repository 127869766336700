import { BaseModel } from '../_common/base-model';

export class UserInfo extends BaseModel {
    'sub': string;
    'name': string;
    'family_name': string;
    'given_name': string;
    'nickname': string;
    'preferred_username': string;
    'locale': string;
    'updated_at': number;
    'user_status': string;
    'last_accessed_at': number;
    'last_used_identity_id': string;
    'provider': string;
    'email': string;
    'mobile_number': string;
    'mobile_number_verified': boolean;
    'email_verified': boolean;
}
