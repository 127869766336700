import { Component, Input, OnInit } from '@angular/core';
import { UserInfo } from 'src/app/_models/user-info';
import { UserService } from 'src/app/_services/user.service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneValidationService } from 'src/app/_services/phone-validation.service';
import { LoaderService } from 'src/app/_services/loader.service';

@Component({
    selector: 'app-verify-mobile-prompt',
    templateUrl: './verify-mobile-prompt.component.html',
    styleUrls: ['./verify-mobile-prompt.component.scss'],
})
export class VerifyMobilePromptComponent implements OnInit {
    @Input() userToVerify: UserInfo | undefined;

    phoneNumberUtil = PhoneNumberUtil.getInstance();
    phoneCodeInputValue = '+49';
    phoneNumberInputValue = '';
    isValid = false;

    constructor(
        private userService: UserService,
        private phoneValidationService: PhoneValidationService,
        private loaderService: LoaderService
    ) {}

    ngOnInit(): void {
        try {
            const phoneNumber = this.phoneNumberUtil.parse(this.userToVerify?.mobile_number);
            this.phoneCodeInputValue = `+${phoneNumber.getCountryCode()}`;
            this.phoneNumberInputValue = phoneNumber.getNationalNumber()?.toString() || '';
        } catch (e) {
            console.log(e);
        }
        this.numberUpdated();
    }

    saveProfileAndVerifyMobileNumber() {
        if (this.userToVerify) {
            this.userToVerify.mobile_number = this.phoneCodeInputValue + this.phoneNumberInputValue;
            this.userService.updateUserProfile(this.userToVerify).subscribe(() => {
                if (this.userToVerify) {
                    this.initPhoneVerification(this.userToVerify);
                }
            });
        }
    }

    initPhoneVerification(user: UserInfo) {
        this.userService.initiatePhoneVerificationId(user).subscribe();
    }

    numberUpdated() {
        try {
            const phoneNumber = this.phoneCodeInputValue + this.phoneNumberInputValue;
            if (this.phoneNumberUtil.isValidNumber(this.phoneNumberUtil.parse(phoneNumber))) {
                this.loaderService.showLoader();
                this.phoneValidationService
                    .validate(this.phoneCodeInputValue + this.phoneNumberInputValue)
                    .subscribe((value) => (this.isValid = value))
                    .add(() => this.loaderService.hideLoader());
            } else {
                this.isValid = false;
            }
        } catch (e) {
            console.log(e);
        }
    }
}
