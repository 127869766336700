export const environment = {
    production: false,
    authConfig: {
        issuer: 'https://rehau-free.cidaas.de',
        redirectUri: window.location.href,
        logoutUrl: window.location.origin,
        clientId: '9db8562d-755d-42bd-beab-940a0079878e',
        scope: 'openid profile email phone',
    },
    dataEndpoint: 'https://api.dev.euerzuhause.de',
    imageKitBaseURL: 'https://ik.imagekit.io/bfn9a0cer/test',
    i18nPath: './assets/i18n/',
    googleAnalytics: 'G-EWPP4EPEEK',
    landingPage: 'https://www.euerzuhause.de',
    cidaasVersion: 3,
    phoneVerification: {
        url: 'https://api.numlookupapi.com',
        apiKey: 'sWoak2n5lZDkmW4c1gFj9UcZP1hRunf9gzoj6Kim',
    },
    companyUrls: [
        {
            id: '63af1ca52adebc87296a7996',
            name: 'Konzepthaus GmbH',
            url: 'https://www.heinzvonheiden.de/kontaktformular',
        },
        // {
        //     id: '63af1ca52adebc87296a7998',
        //     name: 'HVO Massivhaus GmbH',
        //     url: '',
        // },
        // {
        //     id: '63af1ca62adebc87296a799a',
        //     name: 'HvHLange',
        //     url: '',
        // },
        // {
        //     id: '63af1ca62adebc87296a799c',
        //     name: 'Ring Immobilienservice',
        //     url: '',
        // },
        {
            id: '63af1ca72adebc87296a799e',
            name: 'KAMPA',
            url: 'https://www.kampa.de/kontakt/',
        },
        {
            id: '63af1ca72adebc87296a79a1',
            name: 'LehnerHaus',
            url: 'https://www.lehner-haus.de/index.php/kontakt',
        },
        {
            id: '63af1ca82adebc87296a79a4',
            name: 'Regnauer',
            url: 'https://www.regnauer.de/hausbau/dialog/',
        },
        // {
        //     id: '63cfee720f590deefd59f861',
        //     name: 'Gussek Haus Old',
        //     url: '',
        // },
        {
            id: '641afac17583b9772af22e48',
            name: 'TALBAU-Haus',
            url: 'https://www.talbau-haus.de/kontakt-infomaterial/',
        },
        {
            id: '642294f056a3a439aec9fa56',
            name: 'OHB Hausbau-Gruppe',
            url: 'https://www.ohb-hausbau.de/kontakt/',
        },
        {
            id: '64885aa2cf6b2f270632794b',
            name: 'Spektral Haus GmbH',
            url: 'https://www.spektral-haus.de/kontakt/',
        },
        {
            id: '64a3dcdf55f589ad8685da9a',
            name: 'Hauswärts Consulting GmbH',
            url: 'https://hauswaerts.de/kontakt/',
        },
        {
            id: '64faf4d08c72a36a2f81ae5c',
            name: 'Gruber Holzhaus GmbH',
            url: 'https://www.gruber-holzhaus.de/kontakt/',
        },
        {
            id: '650dbbff01e80503875852de',
            name: 'Gussek Haus',
            url: 'https://www.gussek-haus.de/kontakt/',
        },
    ],
};
