import { BaseModel } from './base-model';
import { PaginationInterface } from './interface/pagination.interface';

export abstract class Pagination extends BaseModel implements PaginationInterface {
    docs!: Array<any>;
    hasNextPage!: boolean;
    hasPrevPage!: boolean;
    limit!: number;
    nextPage!: number | null;
    page!: number;
    pagingCounter!: number;
    prevPage!: number | null;
    totalDocs!: number;
    totalPages!: number;

    protected constructor(pagination: Pagination) {
        super(pagination);
    }
}
