import { House } from 'src/app/_models/leads';
import { Pagination } from 'src/app/_common/pagination';

export class Houses extends Pagination {
    constructor(houses: Houses) {
        super(houses);
        this.docs = this.docs?.map((house) => new House(house));
    }

    docs!: House[];
}
