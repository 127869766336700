import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from '../../_services/loader.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
    @Input() background = false;
    isVisible!: Observable<boolean>;

    constructor(private loaderService: LoaderService) {}

    ngOnInit(): void {
        this.isVisible = this.loaderService.isLoaderVisibleObservable();
    }
}
