import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private loaderCount = 0;
    private subject = new Subject<boolean>();

    constructor() {}

    showLoader(): void {
        this.loaderCount++;
        this.subject.next(this.isLoaderVisible());
    }

    hideLoader(): void {
        this.loaderCount--;
        this.subject.next(this.isLoaderVisible());
    }

    isLoaderVisible(): boolean {
        return !!this.loaderCount;
    }

    isLoaderVisibleObservable(): Observable<any> {
        return this.subject.asObservable();
    }
}
