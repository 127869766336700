import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ModalService } from '../../_services/modal.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() id!: string;
    @Input() class!: string;
    private readonly element: any;

    constructor(private modalService: ModalService, private el: ElementRef, private renderer: Renderer2) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // const modal = this;

        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        // this.element.addEventListener('click', (e: any) => {
        //     if (e.target.className === 'modal') {
        //         modal.close();
        //     }
        // });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
        if (this.modalService.hasActiveModals) {
            this.renderer.selectRootElement('body', true).classList.add('app-modal');
        }

        // this.renderer.addClass(document.getElementsByClassName('grid-container')[0], 'disable-scroll');
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        // console.log('modal destroy');
        this.modalService.remove(this.id);

        if (!this.modalService.hasActiveModals) {
            this.renderer.selectRootElement('body', true).classList.remove('app-modal');
        }
        this.element.remove();
    }

    // TODO: Remove below code if not required in the future

    // open modal
    // open(): void {
    //     this.element.style.display = 'block';
    //     document.body.classList.add('modal-open');
    // }

    // close modal
    // close(): void {
    //     this.element.style.display = 'none';
    //     document.body.classList.remove('modal-open');
    // }
}
