import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PasswordPolicy } from 'src/app/_models/password-policy';
import { environment } from 'src/environments/environment';
import { ApiDataService } from '../_abstract/api-data.service';
import { setCookie } from '../_common/cookies.utils';
import { QueryParams } from '../_common/helper/query-params';
import { RequestId } from '../_models/request-id';
import { User } from '../_models/user';
import { UserInfo } from '../_models/user-info';
import { UserInfoDetails } from '../_models/user-info-details';
import { DataService } from './data.service';
import { UtilsService } from 'src/app/_services/utils.service';

export const COOKIE_DOMAINS = ['.euerzuhause.de', '.euerzuhause.io', 'localhost'];
export const PHONE_VERIFICATION_COOKIE_NAME = 'ezh-phone-verification';

@Injectable({
    providedIn: 'root',
})
export class UserService extends ApiDataService {
    token!: string;
    onLogin = new EventEmitter();
    onLogout = new EventEmitter();
    changeEvent = new EventEmitter();
    loginRegisterPromptVisible = false;

    constructor(public oauthService: OAuthService, private router: Router, private utilsService: UtilsService, dataService: DataService) {
        super(dataService, '', environment.authConfig.issuer);
    }

    getUser(): User | null {
        const ic = this.oauthService.getIdentityClaims() as object;
        return ic ? new User(ic) : null;
    }

    getAccessToken(): string {
        return this.oauthService.getAccessToken();
    }

    getUserInfoFromAPI(): Observable<UserInfo> {
        const cidaasEndpoint = environment.cidaasVersion === 2 ? 'users-srv/userinfo/profile' : 'users-srv/userinfo/' + this.getUser()?.sub;
        return this.get(new QueryParams(), cidaasEndpoint).pipe(
            map((response: UserInfo) => {
                return new UserInfo(response);
            })
        );
    }

    getUserInfoDetailsFromAPI(): Observable<UserInfoDetails> {
        const cidaasEndpoint = 'users-srv/internal/userinfo/profile';
        return this.get(new QueryParams(), cidaasEndpoint).pipe(
            map((response) => {
                return new UserInfoDetails(response.data);
            })
        );
    }

    getPasswordPolicy(): Observable<PasswordPolicy> {
        return this.get(new QueryParams(), 'password-policy-srv/policy').pipe(
            map((response: UserInfo) => {
                return new PasswordPolicy(response);
            })
        );
    }

    updateUserProfile(resource: UserInfo): Observable<{ data: { updated: boolean } }> {
        return this.put(resource, 'users-srv/user/profile/' + this.getUser()?.sub).pipe(
            map((response) => {
                this.changeEvent.emit();
                return response;
            })
        );
    }

    changePassword(resource: { new_password: string; old_password: string; confirm_password: string }): Observable<any> {
        return this.post(Object.assign(resource, { sub: this.getUser()?.sub }), 'users-srv/changepassword').pipe(
            map((response) => {
                this.changeEvent.emit();
                return response;
            })
        );
    }

    // Session exists and has valid token
    isLoggedIn(): boolean {
        return !!(this.oauthService.getAccessToken() && this.oauthService.hasValidAccessToken());
    }

    // Session exists but token is invalid
    isInvalidSession(): boolean {
        return !!(this.oauthService.getAccessToken() && !this.oauthService.hasValidAccessToken());
    }

    login(redirectPath = '', hashForCidaas = ''): void {
        this.token = this.oauthService.getAccessToken();
        if (this.token && this.oauthService.hasValidAccessToken()) {
            redirectPath ? this.router.navigate([redirectPath]).then(() => this.onLogin.emit()) : this.onLogin.emit();
        } else {
            this.oauthService.redirectUri = redirectPath
                ? `${this.utilsService.getBaseUrlWithPath()}${redirectPath}`
                : window.location.href;
            this.oauthService.resetImplicitFlow();
            this.oauthService.loadDiscoveryDocument().then((doc) => {
                this.oauthService.initLoginFlow('', {
                    disableNonceCheck: true,
                    disableOAuth2StateCheck: true,
                    customHashFragment: hashForCidaas ? hashForCidaas : window.location.hash || `#${window.location.search}`,
                });
            });

            // Old login method
            // this.oauthService
            //     .loadDiscoveryDocumentAndLogin({
            //         onTokenReceived: () => {
            //             this.token = this.oauthService.getAccessToken();
            //             this.onLogin.emit();
            //         },
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //         this.systemMsgService.error('generic.auth-error-description').subscribe(() => this.oauthService.initImplicitFlow());
            //     });
        }
    }

    logout(callback?: () => void): void {
        const postLogout = () => {
            this.oauthService.logOut(true);
            if (callback) {
                callback();
            }
            this.onLogout.emit();
        };
        // CORS Workaround
        // Service logOut method creates state which is not correctly recognized when login back
        // Calling directly end_session works, but is forcing redirection, only workaround was to use fetch
        fetch(
            environment.authConfig.issuer +
                '/session/end_session' +
                new QueryParams({ access_token_hint: this.oauthService.getAccessToken() }),
            {
                method: 'GET',
                mode: 'no-cors',
            }
        )
            .then(() => postLogout())
            .catch(() => postLogout());
    }

    initiatePhoneVerificationId(userInfo: UserInfo) {
        const expirationInMs = 5 * 60 * 1000;
        COOKIE_DOMAINS.forEach((domain) =>
            setCookie({ name: PHONE_VERIFICATION_COOKIE_NAME, value: window.location.href, domain, expirationInMs })
        );
        return this.getCidaasRequestId().pipe(
            map((response) => {
                this.initatePhoneVerification(response.data.requestId, userInfo);
            })
        );
    }

    private initatePhoneVerification(requestId: string, userInfo: UserInfo) {
        this.buildFormAndSubmit({
            path: `${environment.authConfig.issuer}/verification-srv/account/initiate`,
            params: {
                requestId,
                email: userInfo.email,
                verificationMedium: 'sms',
                processingType: 'CODE',
            },
        });
    }

    getCidaasRequestId(): Observable<RequestId> {
        return this.post(
            {
                client_id: environment.authConfig.clientId,
                redirect_uri: environment.authConfig.redirectUri,
                response_type: 'code',
                response_mode: 'fragment',
                scope: environment.authConfig.scope,
                nonce: new Date().getTime().toString(),
            },
            'authz-srv/authrequest/authz/generate'
        ).pipe(map((response) => new RequestId(response)));
    }

    private buildFormAndSubmit({ path, params, method }: { path: string; params: Record<string, string>; method?: string }) {
        const form = document.createElement('form');
        form.setAttribute('method', method ?? 'POST');
        form.setAttribute('action', path);

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);
                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();
    }

    setLoginRegisterPromptVisible(value: boolean) {
        this.loginRegisterPromptVisible = value;
    }

    getLoginRegisterPromptVisible() {
        return this.loginRegisterPromptVisible;
    }
}
