import { BaseModel } from '../_common/base-model';

export class User extends BaseModel {
    email!: string;
    locale!: string;
    name!: string;
    nickname!: string;
    provider!: string;
    roles!: Array<string>;
    scopes!: Array<string>;
    sub!: string;
}
