import { DeserializeInterface } from './interface/deserialize.interface';

export class BaseModel implements DeserializeInterface {
    constructor(data?: any) {
        this.deserialize(data);
    }

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
