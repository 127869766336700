import { BaseModel } from 'src/app/_common/base-model';
import { PaginationInterface } from 'src/app/_common/interface/pagination.interface';
import { Questionnaire, QuestionnaireAnswers } from 'src/app/_models/questionnaire';
import { Attribute, HouseAttributeName, OperationType } from 'src/app/_models/matches-summary';
import { merge } from 'lodash-es';

export enum ImageType {
    cover = 'cover',
    exterior = 'exterior',
    other = 'other',
}

export enum FloorplanType {
    floor_0 = 'floor_0',
    floor_1 = 'floor_1',
    floor_2 = 'floor_2',
}

export enum ImageFileType {
    typeImage = 'typeImage',
    typePdf = 'typePdf',
}

export class Leads extends BaseModel implements PaginationInterface {
    constructor(leads: Leads) {
        super(leads);
        this.docs = this.docs.map((lead) => new Lead(lead));
    }

    docs!: Lead[];

    getUniqueNames(): string[] {
        return [...new Set(this.docs.map((val) => val.company?.name || ''))];
    }
}

export class Lead extends BaseModel {
    constructor(lead: Lead) {
        super(lead);
        this.house = new House(this.house);
        this.company = new Company(this.company);
        merge(this.questionnaire, this.answers);
        this.questionnaire = new Questionnaire(this.questionnaire);
        this.answers = new QuestionnaireAnswers(this.answers);
    }

    id!: string;
    matchId!: string;
    questionnaire!: Questionnaire;
    company!: Company;
    answers!: QuestionnaireAnswers;
    house!: House;
    user!: User;
    distributionStatus!: string;
    algorithm!: string;
    score!: number;
    createdAt!: string;
    updatedAt!: string;

    getSentStatus() {
        return undefined;
        // switch (this.distributionStatus) {
        //     case 'LOW_QUALITY_USER_TYPE':
        //         return 'notSent';
        //     case 'LOW_QUALITY_USER_B2B_CONTACT_REQUESTED':
        //         return 'sent';
        //     default:
        //         return undefined;
        // }
    }
}

export class Company extends BaseModel {
    id?: string;
    foundationIncluded!: boolean;
    constructionType!: 'PREFABRICATED' | 'SOLID';
    isPlotMandatory!: boolean;
    kitchenInteriorIncluded!: boolean;
    name!: string;
    displayName!: string;
    logoUrl!: string;

    getLogoPath(): string {
        return this.logoUrl && new URL(this.logoUrl).pathname;
    }

    getDisplayName(): string {
        return this.displayName ?? this.name;
        // return CompanyDisplayName[this.id as keyof typeof CompanyDisplayName] || this.name;
    }

    getDisplayAttributes(): string[] {
        return Object.values(CompanyAttributeName).filter((attributeName) => this[attributeName]);
    }
}

export enum CompanyAttributeName {
    foundationIncluded = 'foundationIncluded',
    kitchenInteriorIncluded = 'kitchenInteriorIncluded',
}

export class House extends BaseModel {
    constructor(house: House) {
        super(house);
        this.images = this.images?.map((image) => new Image(image));
        this.company = new Company(this.company);
    }

    id!: string;
    companyId!: string;
    company?: Company;
    images!: Image[];
    name!: string;
    houseCode!: string;
    houseType!: string;
    houseStyle!: string;
    houseState!: string;
    price!: number;
    roofStyle!: string;
    energyEfficiency!: string;
    isBarrierFree!: boolean;
    isCarportAvailable!: boolean;
    isSolarSystemAvailable!: boolean;
    isVentilationSystemAvailable!: boolean;
    isAirConditioningAvailable!: boolean;
    totalLivingArea!: number;
    totalFlexrooms!: number;
    numberOfWCs!: number;
    totalRooms!: number;
    numberOfFloors!: number;
    numberOfBedrooms!: number;
    numberOfWorkingRooms!: number;
    numberOfBathrooms!: number;
    numberOfBathroomsGuest!: number;
    numberOfToilets!: number;
    hasDressingRoom!: boolean;
    hasLaundryRoom!: boolean;
    hasEnSuite!: boolean;
    hasOpenLivingRoom!: boolean;
    hasOpenKitchen!: boolean;
    hasUtilityRoom!: boolean;
    hasStoreRoom!: boolean;
    hasOfficeRoom!: boolean;
    hasTechnicalRoom!: boolean;
    hasBalcony!: boolean;
    hasPatio!: boolean;
    createdAt!: string;
    updatedAt!: string;
    slug?: string;

    getDisplayTotalLivingArea() {
        return Math.round(this.totalLivingArea || 0);
    }

    getCoverImage(): Image | undefined {
        return this.images?.find((image) => image.type === ImageType.cover);
    }

    getExteriorImages(): Image[] {
        return this.images?.filter((image) => image.type === ImageType.exterior);
    }

    getFloorplanImages(): Image[] {
        return this.images?.filter(
            (image) => image.type === FloorplanType.floor_0 || image.type === FloorplanType.floor_1 || image.type === FloorplanType.floor_2
        );
    }

    getImages(): Image[] {
        const coverImage = this.getCoverImage();
        return coverImage ? [coverImage, ...this.getExteriorImages()] : this.getExteriorImages();
    }

    getFloorplanPdfs(): Image[] {
        return this.images.filter((image) => image.type === ImageType.other);
    }

    getImagesAndFloorplans(): Image[] {
        return [...this.getImages(), ...this.getFloorplanImages()];
    }

    getImageByFileType(fileType: ImageFileType): Image[] {
        return this.images.filter((image) => image.getFileType() === fileType);
    }

    getDisplayAttributes(attributesToCheck?: (keyof typeof HouseAttributeName)[]): Attribute[] {
        return Object.values(attributesToCheck || HouseAttributeName)
            .filter((attributeName) => this[attributeName])
            .map(
                (attributeName) =>
                    new Attribute({
                        attributeName,
                        attributeValue: this[attributeName],
                        operationType: OperationType.equal,
                    })
            );
    }
}

export class Image extends BaseModel {
    static typeImage = ImageFileType.typeImage;
    static typePdf = ImageFileType.typePdf;
    type!: string;
    url!: string;

    getPath(): string {
        return new URL(this.url).pathname;
    }

    getFileType(): string {
        return this.url.slice(-3).toLowerCase() === 'pdf' ? Image.typePdf : Image.typeImage;
    }
}

export interface User {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
}
