import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SystemMsgService } from '../../_services/system-msg.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-system-msg',
    templateUrl: './system-msg.component.html',
    styleUrls: ['./system-msg.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SystemMsgComponent implements OnInit {
    messages: any = [];
    currentMessage!: {
        type: string;
        title: string;
        message: string;
        buttons: { value?: any; title: string; cssClass?: string }[];
        cssClass: string;
        param: object;
        buttonSubject: Subject<any>;
    };

    constructor(private systemMsgService: SystemMsgService) {}

    ngOnInit(): void {
        this.systemMsgService.getMessage().subscribe((message) => {
            this.messages.push(message);
            if (!this.currentMessage) {
                this.close();
            }
        });
    }

    open(): void {
        // this.modal.open();
    }

    callbackAndClose(button: { value?: any; title?: string }): void {
        if (button?.value) {
            this.currentMessage.buttonSubject.next(button);
        }
        this.currentMessage.buttonSubject.complete();
        this.close();
    }

    close(): void {
        this.currentMessage = this.messages.pop();
    }
}
