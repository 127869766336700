import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './_core/_core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage, ValidationHandler } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from 'src/environments/environment';
import { provideImageKitLoader, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { GoogleAnalyticsService, NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getMatPaginatorIntl } from 'src/app/_common/mat-paginator-intl';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig } from 'ng2-currency-mask';
import { GALLERY_CONFIG, GalleryConfig } from 'ng-gallery';
import { UserService } from 'src/app/_services/user.service';
import { HousesService } from 'src/app/_services/houses.service';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, environment.i18nPath, '.json');
}

export function storageFactory(): OAuthStorage {
    return localStorage;
}

export const authModuleConfig: OAuthModuleConfig = {
    resourceServer: {
        sendAccessToken: true,
        allowedUrls: [environment.dataEndpoint, environment.authConfig.issuer],
    },
};

export function initializeApp(
    userService: UserService,
    housesService: HousesService,
    googleAnalyticsService: GoogleAnalyticsService
): () => Promise<any> {
    return (): Promise<any> =>
        new Promise<void>((resolve) => {
            // this.userService.oauthService.showDebugInformation = true;
            userService.oauthService.loadDiscoveryDocument().then((doc) => {
                userService.oauthService
                    .tryLogin({
                        disableNonceCheck: true,
                        disableOAuth2StateCheck: true,
                        customHashFragment: window.location.hash || `#${window.location.search}`,
                    })
                    .then((success) => {
                        if (success) {
                            window.history.replaceState(null, '', window.location.pathname);
                            googleAnalyticsService.event('signup_completed', 'users');
                        }
                        if (userService.isLoggedIn()) {
                            housesService.getFavourites().subscribe();
                            housesService.getCompare().subscribe();
                        }
                        resolve();
                    })
                    .catch((reason) => {
                        console.log(reason);
                        resolve();
                    });
            });
        });
}

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    prefix: '€ ',
    thousands: '.',
    decimal: ',',
    align: 'left',
    precision: 0,
    allowNegative: false,
    suffix: '',
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        OAuthModule.forRoot(),
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics),
        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        CoreModule,
        SharedModule,
    ],
    providers: [
        { provide: AuthConfig, useValue: environment.authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: ValidationHandler, useClass: JwksValidationHandler },
        { provide: OAuthStorage, useFactory: storageFactory },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                subscriptSizing: 'dynamic',
            },
        },
        { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
        { provide: MatPaginatorIntl, useValue: getMatPaginatorIntl() },
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        {
            provide: GALLERY_CONFIG,
            useValue: {
                autoHeight: true,
                imageSize: 'cover',
                slidingDuration: 250,
            } as GalleryConfig,
        },
        provideImageKitLoader(environment.imageKitBaseURL),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            multi: true,
            deps: [UserService, HousesService, GoogleAnalyticsService],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
