import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { User } from 'src/app/_models/user';
import { SystemMsgService } from 'src/app/_services/system-msg.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HousesService } from 'src/app/_services/houses.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    user: User | null;
    userFirstChar: string | undefined;

    @Input() path: string | undefined;

    constructor(
        private userService: UserService,
        private systemMsgService: SystemMsgService,
        private router: Router,
        private housesService: HousesService
    ) {
        this.user = userService.getUser();
        this.userService.oauthService.events.subscribe(() => {
            this.getUserDetails();
        });
    }

    ngOnInit(): void {
        this.getUserDetails();
    }

    getFavouritesCount() {
        return this.housesService.getFavouritesCount();
    }

    getUserDetails() {
        this.user = this.userService.getUser();
        this.userFirstChar = this.user?.name?.charAt(0);
    }

    login() {
        this.userService.login('/users/results', 'login');
    }

    logout() {
        this.userService.logout(() => this.router.navigate(['/users/logout-success']));
    }

    logoClick() {
        if (this.path === 'questionnaire') {
            this.systemMsgService.confirm('generic.leave-confirmation').subscribe(() => (window.location.href = environment.landingPage));
        } else {
            window.location.href = environment.landingPage;
        }
    }

    getCompareCount() {
        return this.housesService.getCompareCount();
    }

    getCompareIds() {
        return this.housesService.compare.join(',');
    }
}
