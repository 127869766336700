import { Injectable } from '@angular/core';
import { fromEvent, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HistoryService {
    history = fromEvent(window, 'popstate').pipe(
        tap((evt) => {
            this.state = (evt as PopStateEvent).state;
        })
    );
    state: any | undefined;

    constructor() {
        this.state = history.state;
    }
}
