import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from '../../_services/loader.service';

@Component({
    selector: 'app-main-loader',
    templateUrl: './main-loader.component.html',
    styleUrls: ['./main-loader.component.scss'],
})
export class MainLoaderComponent implements OnInit {
    @Input() background = true;
    isVisible!: Observable<boolean>;

    constructor(private loaderService: LoaderService) {}

    ngOnInit(): void {
        this.isVisible = this.loaderService.isLoaderVisibleObservable();
    }
}
