import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { SystemMsgService } from './system-msg.service';
import { AppError } from '../_common/error/app-error';
import { BadDataError } from '../_common/error/bad-data-error';
import { UnauthorizedError } from '../_common/error/unauthorized-error';
import { ForbiddenError } from '../_common/error/forbidden-error';
import { ConflictError } from '../_common/error/conflict-error';
import { NotFoundError } from '../_common/error/not-found-error';
import { RequestLimitExceededError } from '../_common/error/request-limit-exceeded-error';
import { HttpOptionsInterface } from '../_common/interface/http-options.interface';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    protected constructor(
        private http: HttpClient,
        private systemMsgService: SystemMsgService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {}

    get(path = '', options?: HttpOptionsInterface): Observable<any> {
        return this.http.get(path, options).pipe(catchError((err) => this.handleError(err)));
    }

    post(path = '', body?: object, options?: HttpOptionsInterface): Observable<any> {
        return this.http.post(path, body, options).pipe(catchError((err) => this.handleError(err)));
    }

    patch(path = '', body?: object, options?: HttpOptionsInterface): Observable<any> {
        return this.http.patch(path, body, options).pipe(catchError((err) => this.handleError(err)));
    }

    put(path = '', body?: object, options?: HttpOptionsInterface): Observable<any> {
        return this.http.put(path, body, options).pipe(catchError((err) => this.handleError(err)));
    }

    delete(path = '', options?: HttpOptionsInterface): Observable<any> {
        return this.http.delete(path, options).pipe(catchError((err) => this.handleError(err)));
    }

    private handleError<T>(error: HttpErrorResponse): ObservableInput<any> {
        const msg = `${error.name} failed: ${error.message}`;
        console.error(msg);
        this.googleAnalyticsService.event(error?.status?.toString(), 'error', msg);

        switch (error.status) {
            case 0:
                this.systemMsgService.error(error.statusText);
                return throwError(new AppError(error));
            case 400:
                return throwError(new BadDataError(error));
            case 401:
                this.systemMsgService.error(new UnauthorizedError(error).toString()).subscribe(() => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('id_token_claims_obj');
                    window.location.reload();
                });
                return throwError(new UnauthorizedError());
            case 403:
                return throwError(new ForbiddenError(error));
            case 404:
                return throwError(new NotFoundError(error));
            case 409:
                return throwError(new ConflictError(error));
            case 429:
                this.systemMsgService.error(error.statusText);
                return throwError(new RequestLimitExceededError());
            case 500:
                const errorObject = new AppError(error);
                this.systemMsgService.error(errorObject.toString());
                return throwError(errorObject);
            default:
                return throwError(new AppError(error));
        }
    }
}
