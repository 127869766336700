import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SystemNotificationService {
    private subject = new Subject<{
        message: string;
        param: any;
    }>();

    constructor() {}

    notify(message: string, param?: object): void {
        this.subject.next({
            message,
            param,
        });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}
