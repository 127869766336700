import { isArray } from 'lodash-es';

export class AppError {
    constructor(public errorData?: any) {}

    get message(): string {
        return isArray(this.errorData?.error?.message) ? this.errorData?.error?.message.join('\n') : this.errorData?.error?.message || '';
    }

    toString(): string {
        return this.message;
    }
}
