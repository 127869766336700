import { Injectable } from '@angular/core';
import { BrowserPlatformLocation } from 'node_modules/@angular/common';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor(private browserPlatformLocation: BrowserPlatformLocation) {}

    getBaseUrlWithPath() {
        const baseHref = this.browserPlatformLocation.getBaseHrefFromDOM().replace(/(\/*$)/g, '');
        return `${window.location.origin}${baseHref}`;
    }
}
