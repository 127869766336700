import { Questionnaire } from 'src/app/_models/questionnaire';
import { BaseModel } from 'src/app/_common/base-model';

export class MatchesSummary extends BaseModel {
    constructor(matchesSummary: MatchesSummary) {
        super(matchesSummary);
        this.preview = new Preview(this.preview);
    }

    id!: string;
    questionnaire!: Questionnaire;
    answers!: Questionnaire;
    preview!: Preview;
    status!: 'IN_PROGRESS' | 'COMPLETED';
    userType?: 'GAMBLER' | 'DREAMER_LIGHT_NO_FINANCING' | 'DREAMER_LIGHT_WITH_FINANCING' | 'DREAMER' | 'BUILDER';
    features?: { skipPreResultsPage?: boolean };
    algorithm!: string;
    createdAt!: string;
    updatedAt!: string;

    getPreviewImagesUrls(): string[] {
        return this.preview.images.map((image) => `${image.url}?blur=1`);
    }
}

class Preview extends BaseModel {
    housesCount!: number;
    images!: Image[];
    attributes!: Attribute[];

    constructor(data: Preview) {
        super(data);
        this.images = this.images?.map((image) => new Image(image));
    }

    getDisplayAttributes(): Attribute[] {
        const attributesOrder = Object.values(HouseAttributeName);
        const attributes = this.attributes?.filter((attribute) => Object.values(HouseAttributeName).includes(attribute.attributeName));
        return attributes.sort((a, b) => attributesOrder.indexOf(a.attributeName) - attributesOrder.indexOf(b.attributeName));
    }
}

class Image extends BaseModel {
    type!: string;
    url!: string;

    getPath(): string {
        return new URL(this.url).pathname;
    }
}

export class Attribute extends BaseModel {
    operationType!: OperationType;
    attributeName!: HouseAttributeName;
    attributeValue!: number | boolean;
}

export enum OperationType {
    min = 'MIN',
    max = 'MAX',
    equal = 'EQUAL',
}

export enum HouseAttributeName {
    price = 'price',
    totalLivingArea = 'totalLivingArea',
    totalRooms = 'totalRooms',
    totalFlexrooms = 'totalFlexrooms',
    numberOfWCs = 'numberOfWCs',
    hasEnSuite = 'hasEnSuite',
    hasUtilityRoom = 'hasUtilityRoom',
    hasOfficeRoom = 'hasOfficeRoom',
    hasBalcony = 'hasBalcony',
    hasPatio = 'hasPatio',
    numberOfBathrooms = 'numberOfBathrooms',
    hasStoreRoom = 'hasStoreRoom',
    hasOpenKitchen = 'hasOpenKitchen',
    hasDressingRoom = 'hasDressingRoom',
    hasLaundryRoom = 'hasLaundryRoom',
}
