<div *ngIf="currentMessage" class="modal">
    <div class="modal-body {{ currentMessage.cssClass }}">
        <div class="title">
            <mat-icon class="type-icon warning">warning_amber</mat-icon>
            <mat-icon class="type-icon error">error_outlined</mat-icon>
            <span>{{ currentMessage.title | translate: currentMessage.param }}</span>
        </div>
        <div class="message">{{ currentMessage.message | translate: currentMessage.param }}</div>
        <div class="buttons">
            <ng-container *ngFor="let button of currentMessage.buttons">
                <button color="accent" *ngIf="button?.cssClass === 'secondary'" (click)="callbackAndClose(button)" mat-stroked-button>
                    {{ button.title | translate }}
                </button>
                <button color="accent" *ngIf="button?.cssClass !== 'secondary'" (click)="callbackAndClose(button)" mat-flat-button>
                    {{ button.title | translate }}
                </button>
            </ng-container>
        </div>
    </div>
</div>
<div *ngIf="currentMessage" class="modal-background"></div>
