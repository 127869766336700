import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/_shared/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'src/app/_core/_core.module';
import { MatButtonModule } from '@angular/material/button';
import { GalleryViewerComponent } from './gallery-viewer/gallery-viewer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FooterComponent } from './footer/footer.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterLink } from 'node_modules/@angular/router';
import { LoginRegisterPromptComponent } from './login-register-prompt/login-register-prompt.component';
import { VerifyMobilePromptComponent } from './verify-mobile-prompt/verify-mobile-prompt.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
    declarations: [HeaderComponent, GalleryViewerComponent, FooterComponent, LoginRegisterPromptComponent, VerifyMobilePromptComponent],
    imports: [
        CommonModule,
        TranslateModule,
        CoreModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        RouterLink,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
    ],
    exports: [HeaderComponent, GalleryViewerComponent, FooterComponent, LoginRegisterPromptComponent, VerifyMobilePromptComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
