import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Image } from 'src/app/_models/leads';
import { register } from 'swiper/element/bundle';

// install Swiper modules

@Component({
    selector: 'app-gallery-viewer',
    templateUrl: './gallery-viewer.component.html',
    styleUrls: ['./gallery-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GalleryViewerComponent implements AfterViewInit {
    @Output() closeEvent = new EventEmitter();
    @Input() images!: Image[];

    @ViewChild('swiperRef')
    swiperRef: ElementRef | undefined;

    imageRef = Image;

    constructor(private cdref: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        register();
        this.swiperRef?.nativeElement.initialize();
        this.cdref.detectChanges();
    }
}
