import { BaseModel } from 'src/app/_common/base-model';

export class PasswordPolicy extends BaseModel {
    data!: {
        policy_name: string;
        minimumLength: number;
        maximumLength: number;
        noOfDigits: number;
        lowerAndUpperCase: boolean;
        createdTime: string;
        updatedTime: string;
        noOfSpecialChars: number;
    };
}
