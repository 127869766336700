import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../_services/data.service';
import { environment } from 'src/environments/environment';
import { QueryParams } from '../_common/helper/query-params';
import { parseTemplate } from 'url-template';
import { map } from 'rxjs/operators';
import { HttpOptionsInterface } from '../_common/interface/http-options.interface';

@Injectable({
    providedIn: 'root',
})
export abstract class ApiDataService {
    public changeEvent = new EventEmitter();
    endpointUrl = '';
    endpointBaseUrl = '';

    protected constructor(
        private dataService: DataService,
        @Inject(String) private path?: string,
        @Inject(String) endpointBaseURL?: string
    ) {
        this.endpointBaseUrl = endpointBaseURL !== undefined ? endpointBaseURL : environment.dataEndpoint;
        this.endpointUrl = this.endpointBaseUrl + (path ? '/' + path : '');
    }

    get(params: QueryParams = new QueryParams(), resourcePath?: string | undefined, options?: HttpOptionsInterface): Observable<any> {
        return this.dataService.get(this.getPath(resourcePath) + params, options);
    }

    post(body: object | undefined, resourcePath?: string | undefined, options?: HttpOptionsInterface): Observable<any> {
        return this.dataService.post(this.getPath(resourcePath), body, options).pipe(
            map((response) => {
                this.changeEvent.emit();
                return response;
            })
        );
    }

    patch(body: object | undefined, resourcePath?: string | undefined, options?: HttpOptionsInterface): Observable<any> {
        return this.dataService.patch(this.getPath(resourcePath), body, options).pipe(
            map((response) => {
                this.changeEvent.emit();
                return response;
            })
        );
    }

    put(body: object | undefined, resourcePath?: string | undefined, options?: HttpOptionsInterface): Observable<any> {
        return this.dataService.put(this.getPath(resourcePath), body, options).pipe(
            map((response) => {
                this.changeEvent.emit();
                return response;
            })
        );
    }

    delete(resourcePath: string, options?: HttpOptionsInterface): Observable<any> {
        return this.dataService.delete(this.getPath(resourcePath), options).pipe(
            map((response) => {
                this.changeEvent.emit();
                return response;
            })
        );
    }

    private getPath(resourcePath: string | undefined): string {
        return parseTemplate(this.endpointUrl + (resourcePath ? '/' + resourcePath : '')).expand({
            /**
             * Here we can replace templated values in URL's if we need values in URL globally replaced for all endpoints
             * For example if we want all calls to reach certain namespace or id
             *
             * Example URL: endpoint/{my-first-var}/namespace/{${my-second-var}}
             * Example key value pairs that will be placed in this object
             * ['my-first-var']: 'first-value',
             * ['my-second-var']: 'second-value',
             *
             */
        });
    }
}
