<app-modal id="verify-mobile-modal">
    <div class="container">
        <div class="title">{{ 'verify-mobile-prompt.title' | translate }}</div>
        <div class="description">
            {{ 'verify-mobile-prompt.description' | translate }}
        </div>
        <div class="number-input">
            <mat-form-field color="accent" appearance="outline" class="mobile-code">
                <mat-label>{{ 'verify-mobile-prompt.country-code-input-label' | translate }}</mat-label>
                <mat-select [(ngModel)]="phoneCodeInputValue" [ngModelOptions]="{ standalone: true }" (ngModelChange)="numberUpdated()">
                    <mat-option
                        *ngFor="let code of phoneNumberUtil.getSupportedRegions()"
                        [value]="'+' + phoneNumberUtil.getCountryCodeForRegion(code)"
                    >
                        {{ code }} +{{ phoneNumberUtil.getCountryCodeForRegion(code) }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field color="accent" appearance="outline" class="white">
                <mat-label>{{ 'verify-mobile-prompt.mobile-number-input-label' | translate }}</mat-label>
                <input matInput required="true" [(ngModel)]="phoneNumberInputValue" (ngModelChange)="numberUpdated()" />
                <mat-error>{{ 'generic.invalid' | translate }}</mat-error>
            </mat-form-field>
        </div>
        <button mat-flat-button color="primary" [disabled]="!isValid" (click)="saveProfileAndVerifyMobileNumber()">
            {{ 'verify-mobile-prompt.verify-button' | translate }}
        </button>
    </div>
</app-modal>
