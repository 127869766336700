import { QueryParamsInterface } from '../interface/query-params.interface';

export class QueryParams {
    [key: string]: any;

    constructor(params?: QueryParamsInterface) {
        Object.assign(this, params);
    }

    toString(): string {
        const paramsAsArray = [];
        let paramsAsString = '';

        for (const prop in this) {
            if (this.hasOwnProperty(prop)) {
                const value = this[prop];
                if (Array.isArray(value)) {
                    value.map((item: string | number | boolean, i: string) => {
                        paramsAsArray.push(prop + '[' + i + ']=' + encodeURIComponent(item));
                    });
                } else {
                    if (value) {
                        paramsAsArray.push(prop + '=' + encodeURIComponent(value));
                    }
                }
            }
        }

        if (paramsAsArray.length) {
            paramsAsString = '?' + paramsAsArray.join('&');
        }

        return paramsAsString;
    }
}
