import { MatPaginatorIntl } from '@angular/material/paginator';

const rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
        return ``;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${page + 1} / ${Math.ceil(length / pageSize)}`;
    // return `${startIndex + 1} - ${endIndex} / ${length}`;
};

export function getMatPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Zeilen pro Seite:';
    paginatorIntl.nextPageLabel = 'Nächste Seite';
    paginatorIntl.previousPageLabel = 'Vorherige Seite';
    paginatorIntl.lastPageLabel = 'Letzte Seite';
    paginatorIntl.firstPageLabel = 'Erste Seite';
    paginatorIntl.getRangeLabel = rangeLabel;

    return paginatorIntl;
}
