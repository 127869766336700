<div class="container">
    <swiper-container
        #swiperRef
        init="false"
        keyboard="true"
        space-between="30"
        zoom="true"
        navigation="true"
        class="swiper-main"
        thumbs-swiper=".swiper-thumbs"
    >
        <swiper-slide class="swiper-slide" *ngFor="let image of images">
            <div class="swiper-zoom-container">
                <img *ngIf="image.getFileType() === imageRef.typeImage" [src]="image.url" alt="" />
            </div>
        </swiper-slide>
    </swiper-container>
    <swiper-container free-mode="true" space-between="10" class="swiper-thumbs" slides-per-view="4">
        <swiper-slide class="swiper-slide" *ngFor="let image of images">
            <div class="swiper-zoom-container">
                <img *ngIf="image.getFileType() === imageRef.typeImage" [src]="image.url" alt="" />
            </div>
        </swiper-slide>
    </swiper-container>
    <button class="button-close" mat-button mat-flat-button color="accent" (click)="closeEvent.emit()">
        <mat-icon>close</mat-icon>
    </button>
</div>
