import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { HistoryService } from 'src/app/_services/history.service';
import { ScriptService } from 'src/app/_services/script.service';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { SystemMsgService } from 'src/app/_services/system-msg.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from 'src/app/_services/utils.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private router: Router,
        private userService: UserService,
        private historyService: HistoryService,
        private scriptService: ScriptService,
        private renderer: Renderer2,
        private googleAnalyticsService: GoogleAnalyticsService,
        private systemMsgService: SystemMsgService,
        private utilsService: UtilsService,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer
    ) {
        this.historyService.history.subscribe();
        this.listenToRouting();
        matIconRegistry.addSvgIcon('compare', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/compare.svg'));
        matIconRegistry.addSvgIcon('compare_outlined', domSanitizer.bypassSecurityTrustResourceUrl('./assets/img/compare_outlined.svg'));
    }

    activeRoute = '';

    ngOnInit(): void {
        const scriptElement = this.scriptService.loadJsScript(
            this.renderer,
            `${this.utilsService.getBaseUrlWithPath()}/assets/addons/feedback/embed.js`
        );
        scriptElement.onload = () => {
            if (window.hasOwnProperty('ezhSetProps')) {
                (window as any).ezhSetProps({
                    dataApiUrl: `${environment.dataEndpoint}/feedback`,
                    dataTemplate: 'questionnaire',
                    dataTriggerIdle: '30',
                    dataTriggerBack: '',
                    dataTriggerScroll: '',
                    dataTriggerTabChange: 'true',
                    callback: (eventName: string, template: string) => {
                        this.googleAnalyticsService.event(`feedback_${eventName}`, 'feedback', template);
                    },
                });
            }
        };
        scriptElement.onerror = () => {
            console.log('Could not load feedback script!');
        };
    }

    private listenToRouting(): void {
        this.router.events.subscribe((value) => {
            this.activeRoute = this.router.url.substring(1);
            if (this.userService.isInvalidSession() && !value.type) {
                this.userService.logout();
                this.systemMsgService.error('generic.auth-error-invalid-session').subscribe(() => this.userService.login('', 'login'));
            }
        });
    }
}
