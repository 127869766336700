<div class="loader">
    <app-main-loader></app-main-loader>
</div>
<app-header></app-header>
THIS IS THE TEST ENVIRONMENT
<div id="content" [ngClass]="activeRoute">
    <router-outlet></router-outlet>
</div>
<app-system-msg></app-system-msg>
<app-system-notification></app-system-notification>
<app-login-register-prompt></app-login-register-prompt>
