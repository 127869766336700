import { Injectable } from '@angular/core';
import { ApiDataService } from 'src/app/_abstract/api-data.service';
import { DataService } from 'src/app/_services/data.service';
import { Observable, tap } from 'rxjs';
import { QueryParams } from 'src/app/_common/helper/query-params';
import { map } from 'rxjs/operators';
import { Houses } from 'src/app/_models/houses';
import { House } from 'src/app/_models/leads';

@Injectable({
    providedIn: 'root',
})
export class HousesService extends ApiDataService {
    constructor(dataService: DataService) {
        super(dataService, `houses`);
    }

    static listTypeComparison = 'COMPARISON';
    static listTypeBookmarks = 'BOOKMARKS';

    favourites: string[] = [];
    compare: string[] = [];

    get(queryParams = new QueryParams()): Observable<Houses> {
        return super.get(queryParams).pipe(map((response) => new Houses(response)));
    }

    getByIdentifier(identifier: string): Observable<House> {
        return super.get(new QueryParams(), identifier).pipe(map((response) => new House(response)));
    }

    getFavourites() {
        return super.get(new QueryParams(), 'bookmarks').pipe(
            tap((response: { houseId: string }[]) => {
                this.favourites = response.map((value) => value.houseId);
            })
        );
    }

    getCompare() {
        return super.get(new QueryParams({ listType: HousesService.listTypeComparison }), 'bookmarks').pipe(
            tap((response: { houseId: string }[]) => {
                this.compare = response.map((value) => value.houseId);
            })
        );
    }

    addToFavourites(id: string) {
        return super.post(new QueryParams({ houseId: id }), 'bookmarks').pipe(tap(() => this.favourites.push(id)));
    }

    removeFromFavourites(id: string) {
        return super.delete(`bookmarks/${id}`).pipe(tap(() => (this.favourites = this.favourites.filter((val) => val !== id))));
    }

    isFavourite(id: string) {
        return !!this.favourites.find((v) => v === id);
    }

    getFavouritesCount() {
        return this.favourites.length;
    }

    getCompareCount() {
        return this.compare.length;
    }

    isCompare(id: string) {
        return !!this.compare.find((v) => v === id);
    }

    removeFromCompare(id: string) {
        return super
            .delete(`bookmarks/${id}?listType=${HousesService.listTypeComparison}`)
            .pipe(tap(() => (this.compare = this.compare.filter((val) => val !== id))));
    }

    addToCompare(id: string) {
        return super
            .post(
                new QueryParams({
                    houseId: id,
                    listType: HousesService.listTypeComparison,
                }),
                'bookmarks'
            )
            .pipe(tap(() => this.compare.push(id)));
    }
}
