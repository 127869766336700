<div id="app-header">
    <div>
        <div class="logo" (click)="logoClick()"></div>
        <div class="user-icon" *ngIf="this.user" [matMenuTriggerFor]="menu">
            {{ userFirstChar }}
        </div>
        <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink="/suche" [queryParams]="{ isBookmarked: true, limit: 11 }">
                <mat-icon>favorite</mat-icon>
                <span>{{ 'shared.header.favourites' | translate }} ({{ getFavouritesCount() }})</span>
            </button>
            <button mat-menu-item routerLink="/suche/compare/{{ getCompareIds() }}">
                <mat-icon svgIcon="compare"></mat-icon>
                <span>{{ 'shared.header.compare' | translate }} ({{ getCompareCount() }})</span>
            </button>
            <button mat-menu-item routerLink="/users/results">
                <mat-icon>list</mat-icon>
                <span>{{ 'shared.header.results' | translate }}</span>
            </button>
            <button mat-menu-item routerLink="/users/profile">
                <mat-icon>person</mat-icon>
                <span>{{ 'shared.header.profile' | translate }}</span>
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>{{ 'shared.header.logout' | translate }}</span>
            </button>
        </mat-menu>
        <div class="button" *ngIf="!this.user" (click)="login()">{{ 'shared.header.login' | translate }}</div>
    </div>
</div>
