<app-modal *ngIf="getLoginRegisterPromptVisible()" id="login-register-prompt">
    <div class="content">
        <div class="image"></div>
        <div class="text">
            <div class="header">
                <mat-icon class="link" (click)="hide()">close</mat-icon>
            </div>
            <div class="description top">
                {{ 'shared.login-register-prompt.description' | translate }}
            </div>
            <ul>
                <li>
                    <mat-icon>check</mat-icon>
                    {{ 'shared.login-register-prompt.list-items.0' | translate }}
                </li>
                <li>
                    <mat-icon>check</mat-icon>
                    {{ 'shared.login-register-prompt.list-items.1' | translate }}
                </li>
                <li>
                    <mat-icon>check</mat-icon>
                    {{ 'shared.login-register-prompt.list-items.2' | translate }}
                </li>
                <li>
                    <mat-icon>check</mat-icon>
                    {{ 'shared.login-register-prompt.list-items.3' | translate }}
                </li>
            </ul>
            <div class="actions">
                <div class="button-blue" (click)="register()">{{ 'shared.login-register-prompt.register-button' | translate }}</div>
                <div class="button-blue" (click)="login()">{{ 'shared.login-register-prompt.login-button' | translate }}</div>
            </div>
        </div>
    </div>
</app-modal>
